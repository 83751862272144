import UnorderedList from '../components/atoms/UnorderedList';

const hatchPositions = [
    {
        title: "Data Scientist",
        time: {startDate: {date: "2024-03", text: "March 2024"}, endDate: {text: "Present"}},
        location: "White River Junction, VT, USA",
        description: <UnorderedList items={[
            "Developed an AI-enabled IoT device, including hardware integration, embedded software, and cloud infrastructure.",
            "Collaborated with the Google Cloud team to implement innovative LLM solutions to Hatch’s key clients.",
            "Developed a computer vision tool to detect and track fare evasion incidents.",
            "Designed and implemented scheduling and route finding optimization algorithms, expanding features and reducing computation time (hours to minutes).",
            "Implemented automated ETL operations for data integration from multiple sources, reducing the need for weeks of manual processing.",
            "Developed a data visualization tool for a complex routing and scheduling utility.",
        ]} />
    }
]

const ouPositions = [
    {
        title: "Graduate Research Assistant",
        time: { startDate: { date: "2021-08", text: "Aug 2021" }, endDate: { date: "2023-12", text: "Dec 2023" } },
        location: "Tulsa, OK, USA",
        description: <UnorderedList items={[
            "Implemented ML models to classify road surface conditions using RWIS weather and speed data, achieving a 0.9+ F1 score.",
            "Developed ML models for travel time estimation on highways using radar speed data, achieving over 90% accuracy.  ",
            // "Implemented parametric modeling of the temporal dynamics of the rate of change on weather data to showcase the importance of subsurface temperature probes in weather stations.",
            "Implemented parametric modeling to illustrate the significance of subsurface temperature probes for temporal dynamics in weather station data.",
            "Pioneered data-driven efficiency measures of road clearance impact on traffic speed during winter storms. ",
            "Conducted statistical analysis on traffic systems, focusing on speed, travel time, and volume.",
            "Developed a real-time IoT Android app for ODOT snowplow trucks, facilitating communication with drivers and data collection from embedded systems.",
            "Programmed an embedded PCB with BeagleBone to collect real-time road surface and ambient temperature data from a truck-mounted IR sensor.",
        ]} />
        ,
    }
];

const salvimarPositions = [
    // {
    //     title: "Software Engineer 2",
    //     time: { startDate: { date: "2021-08", text: "Aug 2021" }, endDate: { date: "2023-06", text: "Present" } },
    //     location: "Tulsa, Oklahoma, USA",
    //     description: "",
    // },
    {
        title: "Software Engineer",
        time: { startDate: { date: "2020-06", text: "Jun 2020" }, endDate: { date: "2021-08", text: "Jul 2021" } },
        location: "Remote work, Germany",
        description: <UnorderedList items={[
            "Developed and tested web applications using React.js, TypeScript, HTML, CSS/SaSS, and Node.js.",
            "Developed Android applications using Kotlin and Java.",
            "Led software projects ensuring top-notch code quality and cohesive team communication.",
            "Experienced in agile methodologies, including Scrum, Kanban, and Pair Programming.",
            "Developed a POC project starter architecture using Node.js, Typescript, MongoDB, and GraphQL.",
        ]} />
        ,
    }
];

const wizmePositions = [
    {
        title: "Data Engineer",
        time: { startDate: { date: "2020-07", text: "Jul 2020" }, endDate: { date: "2020-10", text: "Oct 2020" } },
        location: "Remote work, London, England, UK",
        description: <UnorderedList items={[
            "Developed high-performing and robust web scrapers using Python and Scrapy framework.",
            "Implemented scrapers for data collection from diverse sources, including APIs, static websites, and dynamically rendered content.",
            "Extracted, manipulated, and parsed data into structured formats to ensure data integrity and alignment with the expected database schema.",
        ]} />
    },
];

export { hatchPositions, ouPositions, salvimarPositions, wizmePositions };