import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationItem from '../molecules/NavigationItem';
import './NavigationMenu.scss';
import resumeLink from '../../data/Resume';


const NavigationMenu = ({ id, isVisible, hideMenu }) => {
    const [activePage, setActivePage] = useState('/');
    const location = useLocation();
  
    useEffect(() => {
      setActivePage(location.pathname);
    }, [location]);
  
    const handleItemClick = () => {
      hideMenu();
    };

    return (
        // <nav id={id} className='nav-items'>
        <nav
            id={id}
            className={`nav-items ${isVisible ? 'visible' : ''}`}
        // className={`nav-items ${disableAnimation ? 'disable-animation' : ''} ${isVisible ? 'visible' : ''}`}
        >
            <ol>
                <NavigationItem href="#" title="Home" active={activePage === '/'} _key={'home'} onClick={handleItemClick} />
                <NavigationItem href="#/about" title="About" active={activePage === '/about'} _key={'about'} onClick={handleItemClick} />
                <NavigationItem href="#/contact" title="Contact" active={activePage === '/contact'} _key={'contact'} onClick={handleItemClick} />
                <NavigationItem href="#/awards" title="Awards" active={activePage === '/awards'} _key={'awards'} onClick={handleItemClick} />
                <NavigationItem href={resumeLink} title="Résumé" target="_blank" rel="noopener noreferrer" _key={'resume'} />
                {/* <NavigationItem href="/testimonials" title="Testimonials" active={activePage === 'testimonials'} />
                <NavigationItem href="/miscellaneous" title="Miscellaneous" active={activePage === 'miscellaneous'} /> */}
            </ol>
        </nav>
    );
}

export default NavigationMenu;