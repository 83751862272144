import React from 'react';
import './ContactIcons.scss';
import IconAnchor from '../molecules/IconAnchor';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const ICONS = [
    { icon: <FaLinkedin />, link: 'https://www.linkedin.com/in/mohamed-ali-habib/', alt: 'LinkedIn Page @ /mohamed-ali-habib' },
    { icon: <FaGithub />, link: 'https://github.com/MohamedAliHabib', alt: 'GitHub Page @ /MohamedAliHabib' },
    { icon: <FaEnvelope />, link: 'mailto:ali@mohamedalihabib.com', alt: 'Email me @ ali@mohamedalihabib.com' },
    { icon: <FaXTwitter />, link: 'https://x.com/m_ali_habib', alt: 'X Page @ m_ali_habib' },
];

const ContactIcons = () => {
    return (
        <div className="contact-icons">
            {ICONS && ICONS.map((icon, index) => (
                <div key={`icon-${index}`}>
                    <IconAnchor _key={index} icon={icon.icon} link={icon.link} target="_blank" rel="noopener noreferrer" />
                </div>
            ))}
        </div>
    );
};

export default ContactIcons;